import {
    Select,
    TextField,
    MenuItem,
    Switch,
    InputLabel,
    Input,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Button,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getDatabaseInfo, sendData } from '../api';
import { FeatureSelector } from '../components/uploaders/FeatureSelector';
import { MetroSelector } from '../components/uploaders/MetroSelector';
import { TimezoneSelectors } from '../components/uploaders/TimezoneSelectors';
import CSVReader, { IFileInfo } from 'react-csv-reader';
import headers from '../util/headers';
import {
    HostData,
    ResidentData,
    CommunityData,
    GuestData,
    ContractorData,
    DbInfoInterface,
    UploadData,
    SubmissionData,
    StatusData,
} from '../types';

type BodyWrapperType = {
    isPreview?: boolean;
};
export const CommunityUploader = () => {
    const [dbInfo, setDbInfo] = useState<DbInfoInterface | undefined>(undefined);
    const [isNewMetro, setNewMetro] = useState(false);
    const [metroArea, setMetroArea] = useState<string>('');
    const [name, setName] = useState('');
    const [isSmsAllowed, setIsSmsAllowed] = useState('YES');
    const [accessEmail, setAccessEmail] = useState('gatesentry');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [timezone, setTimezone] = useState('America/Chicago');
    const [otherTimezoneGroup, setOtherTimezoneGroup] = useState('');
    const [isPreview, setPreview] = useState(false);
    const [features, setFeatures] = useState<String[]>([
        'SearchIncludesVendors',
        'EnableEnhancedSearch',
        'ShowMustCallForVerification',
        'IDRequiredForGrant',
    ]);
    const [submitted, setSubmitted] = useState(false);
    const [uploadData, setUploadData] = useState<UploadData | undefined>(undefined);
    const [communityData, setCommunityData] = useState<CommunityData | undefined>(undefined);
    const [completeInfo, setCompleteInfo] = useState<StatusData | undefined>(undefined);
    function addUnique(arr, el) {
        if (!arr.map((el) => el.companyName).includes(el.companyName)) {
            arr.push(el);
        }
    }
    useEffect(() => {
        getDatabaseInfo().then((res) => {
            setDbInfo(res);
        });
    }, []);

    useEffect(() => {
        console.log(uploadData);
    }, [uploadData]);

    useEffect(() => {
        if (dbInfo) {
            setMetroArea(dbInfo.metro[0].get('Name'));
        }
    }, [dbInfo]);
    if (dbInfo === undefined) {
        return <h2>loading</h2>;
    }
    function fileHandler(data, fileInfo: IFileInfo) {
        // let file = e.target.files[0];
        const hosts: HostData[] = [];
        let totalVendorList: ContractorData[] = [];

        //converts csv data into object
        let unexpected: String[] = [];
        let expected: String[] = [];

        const keys = data[0];
        keys.forEach((el, i) => {
            if (!headers.includes(el)) {
                unexpected.push(el);
            }
            if (!keys.includes(headers[i])) {
                expected.push(el);
            }
        });
        if (unexpected.length > 0) {
            alert(`Incorrect headers. List of unexpected headers ${unexpected.join(', ')}`);
            return;
        }
        if (expected.length > 0) {
            alert(`Incorrect headers. List of missing headers ${expected.join(', ')}`);
            return;
        }
        //Removes keys and junk rows
        const realData = data.slice(1).filter((el) => el.length > 10);

        //Returns the data assigned to its key for every row, with seperate subobjects for visitors and residents
        const keyedData = realData.map((el) => {
            return keys.reduce((acc, cur, i) => {
                if (el[i] === '') {
                    return acc;
                }
                if (cur.includes('Resident')) {
                    return {
                        ...acc,
                        residents: { ...acc.residents, [cur]: el[i].trim() },
                    };
                }
                if (cur.includes('Vendor')) {
                    return { ...acc, vendors: { ...acc.vendors, [cur]: el[i].trim() } };
                }
                if (cur.includes('Guest')) {
                    return { ...acc, guests: { ...acc.guests, [cur]: el[i].trim() } };
                }
                return { ...acc, [cur]: el[i].trim() };
            }, {});
        });
        console.log(keyedData);
        let error = false;
        keyedData.forEach((el) => {
            try {
                //set up host data
                let host: HostData = {
                    dbInfo: {
                        name: el['Host Names'],
                        streetNumber: `${el['House/Unit #']}${el['Is Tenant'].toLowerCase().trim() == 'true' && !el['House/Unit #'].includes('-T') ? '-T' : ''
                            }`,
                        streetName: el['Street'],
                        isTenant: el['Is Tenant'].toLowerCase().trim() == 'true',
                        verificationPhrase: el['Secret Code'],
                    },
                };
                let residents: ResidentData[] = [];
                let guests: GuestData[] = [];
                let vendors: ContractorData[] = [];
                if (el.hasOwnProperty('Access Code')) {
                    host.create = el['Access Code'];
                }
                if (el.hasOwnProperty('LeaseExp') && el['LeaseExp']) {
                    host.dbInfo.leaseExp = el['LeaseExp']

                }
                // contact number
                if (el.hasOwnProperty(`Contact1Num`)) {
                    host.dbInfo[`phNum`] = el[`Contact1Num`];
                    host.dbInfo[`phNumName`] = el[`Contact 1 Name`] || 'Authorizer';;
                }

                if (el.hasOwnProperty(`Contact2Num`)) {
                    host.dbInfo[`phNum1`] = el[`Contact2Num`];
                    host.dbInfo[`phNum1Name`] = el[`Contact 2 Name`] || 'Authorizer';;
                }

                if (el.hasOwnProperty(`Contact3Num`)) {
                    host.dbInfo[`phNum2`] = el[`Contact3Num`];
                    host.dbInfo[`phNum2Name`] = el[`Contact 3 Name`] || 'Authorizer';;
                }

                if (el.hasOwnProperty(`Contact4Num`)) {
                    host.dbInfo[`phNum3`] = el[`Contact4Num`];
                    host.dbInfo[`phNum3Name`] = el[`Contact 4 Name`] || 'Authorizer';;
                }
                if (el.hasOwnProperty(`Contact5Num`)) {
                    host.dbInfo[`phNum4`] = el[`Contact5Num`];
                    host.dbInfo[`phNum4Name`] = el[`Contact 5 Name`] || 'Authorizer';;
                }

                // for (let i = 1; i <= 4; i++) {
                //     if (el.hasOwnProperty(`Contact${i}Num`)) {

                //         if(i == 1){
                //             host.dbInfo[`phNum`] = el[`Contact ${i} Num`];
                //             host.dbInfo[`phNumName`] = el[`Contact ${i} Name`] || 'Authorizer';
                //         } else {
                //             host.dbInfo[`phNum${i}Name`] = el[`Contact ${i} Name`];
                //             host.dbInfo[`phNum${i}`] = el[`Contact ${i} Num`];
                //         }



                //         // if (el.hasOwnProperty(`Contact ${i} Name`)) {
                //         //     host.dbInfo[`phNum${i}Name`] = el[`Contact ${i} Name`] || 'Authorizer';
                //         // } else {
                //         //     host.dbInfo[`phNum${i}Name`] = `Authorizer`;
                //         // }
                //         // host.dbInfo[`phNum${i}`] = el[`Contact${i}Num`];
                //     }
                //     if (el.hasOwnProperty(`Contact ${i} Num`)) {
                //         host.dbInfo[`phNum${i}Name`] = el[`Contact ${i} Name`] || 'Authorizer';
                //         host.dbInfo[`phNum${i}`] = el[`Contact ${i} Num`];
                //     }
                // }
                // if (el.hasOwnProperty('Primary Phone #')) {
                //     host.dbInfo.phNum = el['Primary Phone #'];
                // }
                // add residents
                for (let i = 1; i <= 5; i++) {
                    if (!el.residents[`Resident ${i} First Name`]) {
                        continue;
                    }
                    let residentObj: ResidentData = {
                        firstName: el.residents[`Resident ${i} First Name`],
                        lastName: el.residents[`Resident ${i} Last Name`],
                        sendEmail: true,
                        priority: i,
                    };
                    if (el.residents.hasOwnProperty(`Resident ${i} Email`)) {
                        residentObj.email = el.residents[`Resident ${i} Email`];
                    }
                    residents.push(residentObj);
                }
                // add guests
                for (let i = 1; i <= el.guests['Guest Count']; i++) {
                    let GuestObj: GuestData = {
                        name: el.guests[`Guest ${i}`],
                    };
                    guests.push(GuestObj);
                }
                //add vendors
                for (let i = 1; i <= el.vendors['Vendor Count']; i++) {
                    let VendorObj: ContractorData = {
                        companyName: el.vendors[`Vendor ${i}`],
                    };
                    vendors.push(VendorObj);
                    addUnique(totalVendorList, VendorObj);
                }
                //adds to host so we can link it to the right host row after it is created
                host.residents = residents;
                host.guests = guests;
                host.contractors = vendors;

                //add to master list
                hosts.push(host);
            } catch (err) {
                alert(`Bad row: ${el['Host Names']}, error: ${err}`);
                error = true;
                return;
            }
        });
        if (error) {
            return;
        }
        setUploadData({ hosts, contractors: totalVendorList });
    }
    async function submitHandler() {
        try {
            if (uploadData === undefined || communityData === undefined) {
                console.log('bad data');
                return;
            }
            const submissionData: SubmissionData = {
                uploadData,
                community: communityData,
            };
            console.log(submissionData);
            let res = await sendData(submissionData);
            // let res = await fullSetup(submissionData);
            // setCompleteInfo(res);
            // console.log(res);
        } catch (err) {
            console.error(err);
        }
    }
    if (submitted) {
        return (
            <UploaderPageStyle isPreview={isPreview}>
                <h2>Data being processed on server, you will be emailed when it is finished.</h2>
            </UploaderPageStyle>
        );
    }
    return (
        <UploaderPageStyle isPreview={isPreview}>
            <h1>Community Uploader</h1>
            <div id="settings">
                <div id="options">
                    <CSVReader cssClass="csvReader" onFileLoaded={fileHandler} />
                    <Button
                        className="backButton"
                        variant="contained"
                        onClick={() => {
                            setPreview(false);
                            setCommunityData(undefined);
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        className="submitButton"
                        variant="contained"
                        onClick={() => {
                            // console.log(uploadData);
                            // return;
                            if (uploadData == undefined) {
                                alert('Please add a csv of the host data');
                                return;
                            }
                            if (name == '') {
                                alert('Please set a name');
                                return;
                            }
                            if (city == '') {
                                alert('Please set a city');
                                return;
                            }
                            if (state == '') {
                                alert('Please set a state');
                                return;
                            }
                            if (timezone == 'Other') {
                                alert('Please select a timezone');
                                return;
                            }
                            if (isPreview) {
                                submitHandler();
                                setSubmitted(true);
                            } else {
                                setCommunityData({
                                    name: name.trim(),
                                    city: city.trim(),
                                    state: state.trim(),
                                    isSmsAllowed: isSmsAllowed,
                                    accessEmail: accessEmail,
                                    timeZone: timezone.trim(),
                                    metroArea: metroArea.trim(),
                                    features,
                                } as CommunityData);
                                setPreview(true);
                            }
                        }}
                    >
                        {isPreview ? 'Submit' : 'Preview Submission'}
                    </Button>
                </div>
                {!isPreview ? (
                    <>
                        <TextField
                            className="option muiItem"
                            label="Name"
                            variant="outlined"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                        <div className='addSection'>
                            <TextField
                                className="addTextField"
                                label="City"
                                variant="outlined"
                                value={city}
                                onChange={(e) => {
                                    setCity(e.target.value);
                                }}
                            />
                            <TextField
                                className="addTextField"
                                label="State"
                                variant="outlined"
                                value={state}
                                onChange={(e) => {
                                    setState(e.target.value);
                                }}
                            />
                        </div>
                        <div className="container">
                            <Select
                                id="smsAllowedSelector"
                                label="smsAllowed"
                                variant="outlined"
                                value={isSmsAllowed} // Ensure the correct value is set as string
                                onChange={(e) => {
                                    setIsSmsAllowed(e.target.value as string);
                                }}
                            >
                                <MenuItem value="YES">Yes</MenuItem>
                                <MenuItem value="NO">No</MenuItem>
                            </Select>
                            <InputLabel htmlFor="smsAllowedSelector">Sms Allowed</InputLabel>
                            <Select
                                id="accessEmailSelector"
                                label="accessEmail"
                                variant="outlined"
                                value={accessEmail} // Ensure the correct value is set as string
                                onChange={(e) => {
                                    setAccessEmail(e.target.value as string);
                                }}
                            >
                                <MenuItem value="gatesentry">gatesentry</MenuItem>
                                <MenuItem value="sentrysolo">sentrysolo</MenuItem>
                                <MenuItem value="gatesentrysentrysolo">gatesentrysentrysolo</MenuItem>
                                <MenuItem value="gatesentryvirtualkey">gatesentryvirtualkey</MenuItem>
                                <MenuItem value="gatesentrysentrypass">gatesentrysentrypass</MenuItem>
                                <MenuItem value="gatesentrysentrysolosentrypass">gatesentrysentrysolosentrypass</MenuItem>
                                
                            </Select>
                            <InputLabel htmlFor="accessEmailSelector">Access Email</InputLabel>
                        </div>
                        <MetroSelector
                            isNewMetro={isNewMetro}
                            metroArea={metroArea}
                            dbInfo={dbInfo}
                            setNewMetro={setNewMetro}
                            setMetroArea={setMetroArea}
                        />
                        <TimezoneSelectors
                            timezone={timezone}
                            setTimezone={setTimezone}
                            dbInfo={dbInfo}
                            otherTimezoneGroup={otherTimezoneGroup}
                            setOtherTimezoneGroup={setOtherTimezoneGroup}
                        />
                        <FeatureSelector dbInfo={dbInfo} features={features} setFeatures={setFeatures} />
                    </>
                ) : (
                    <div id="preview">
                        <div id="communityInfo">
                            Community Info
                            {communityData &&
                                Object.keys(communityData).map((key) => (
                                    <div className="row" key={key}>
                                        <div className="key">{key}:</div>
                                        <div className="value">
                                            {typeof communityData[key] == 'object'
                                                ? communityData[key].join(', \n').replace(/([A-Z])/g, ' $1')
                                                : communityData[key]}
                                        </div>
                                    </div>
                                ))}
                            <div className="row">
                                <div className="key">Host Count:</div>
                                <div className="value">{uploadData?.hosts.length}</div>
                            </div>
                            <div className="row">
                                <div className="key">Contractor Count:</div>
                                <div className="value">{uploadData?.contractors.length}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </UploaderPageStyle>
    );
};
const UploaderPageStyle = styled.main<BodyWrapperType>`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 1.5rem;
    overflow-y: scroll;
    h1 {
        text-align: center;
        margin: 0;
        font-size: 1.75rem;
        margin-bottom: 1rem;
    }
    #settings {
        display: flex;
        flex-direction: column;
        width: 60%;
        max-width: 600px;
        margin: 0 auto;
    }
    .option {
        margin: 0.5rem auto;
        display: grid;
        width: 100%;
    }
    #switchContainer {
        margin: 0 1rem;
    }
    .muiItem {
        flex-grow: 1;
    }
    .tzItems {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        label {
            text-align: center;
            padding: 0.5rem;
        }
    }
    .container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        label {
            text-align: center;
            padding: 0.5rem;
        }
    }
    .tzG {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .optContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        label {
            text-align: center;
            padding: 0.5rem;
        }
        .optItem {
            align-self: center;
        }
    }
    .featureList {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    #timezoneSelectors {
        display: flex;
    }
    #metroSelect {
        display: flex;
    }
    #options {
        display: flex;
        justify-content: space-between;
        Button {
            text-transform: none;
            color: white;
            font-weight: bold;
            margin: 10px;
        }
        .submitButton {
            background: ${(p) => {
        return p.isPreview ? 'var(--green)' : 'var(--gs-red)';
    }};
        }
        .backButton {
            display: ${(p) => (p.isPreview ? 'initial' : 'none')};
            background: var(--gs-red);
        }
        .csv-reader-input {
            display: grid;
            justify-content: center;
            align-items: center;
        }
    }
    #preview {
        text-align: center;
        font-size: 0.9rem;
        margin: 0.5rem;
        .row {
            display: flex;
            justify-content: space-between;
            max-width: 500px;
            margin: 0 auto;
        }
    }
    .csvReader {
        display: grid;
        justify-content: center;
        align-items: center;
        display: ${(p) => (!p.isPreview ? 'grid' : 'none')};
        .csv-input {
            font-size: 0.9rem;
        }
    }
    .addSection {
        display: flex;
        justify-content: space-between; /* Adjusts spacing between the fields */
        align-items: center; /* Vertically centers the items */
        gap: 16px; /* Optional: space between the fields */
        margin-bottom: 10px;
    }

    .addTextField {
        flex: 1; /* Ensures the fields take equal space */
        margin-right: 8px; /* Adds space between the fields (optional) */
    }
`;
